<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Settings">
            <validation-observer ref="updateSettingsValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Mode"
                      label-for="mode"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="mode"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.mode"
                          :options="modeOptions"
                        />
                        <span class="text-danger error-msg">{{
                          errors[0]
                        }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      loader: false,
      form: {
        mode: null,
      },
      modeOptions: [
        { value: null, text: 'Please select type', disabled: true },
        { value: 1, text: 'Turn on' },
        { value: 0, text: 'Turn off' },
      ],
    }
  },
  async mounted() {
    await this.fetchSettings()
  },

  methods: {

    async fetchSettings() {
      try {
        this.loader = true
        const resp = await axios.get('/admin/show-settings')
        this.form.mode = resp.data.data.is_offline_mode
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    onSubmit() {
      this.$refs.updateSettingsValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const resp = await axios.post('/admin/update-settings', {
              is_offline_mode: this.form.mode,
            })
            if (resp.status === 200) {
              this.$nextTick(() => {
                this.$refs.updateSettingsValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Settings updated!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'Offline mode has been changed successfully!',
                },
              })
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
  },
}
</script>
